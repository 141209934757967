<i18n>
{
  "de": {
    "ecDynamic": {
      "title": "In der Liegenschaft definiertes Stromprodukt (Netz)",
      "body": "Auf Liegenschaftsebene kann im Bereich \"Stromkonfiguration\" festgelegt werden, welches Stromprodukt der Liegenschaft zugeordnet werden soll. Der Energieträger \"In der Liegenschaft definiertes Stromprodukt (Netz)\" hat die Funktion eines Platzhalters für die vordefinierten und selbsterstellten Stromprodukte, welche in den Einstellungen/Energieträger & Emissionsfaktoren eingesehen werden können. Der Zusatz \"(Netz)\" umschreibt den Umstand, dass nur Netzbezug berücksichtigt wird, auch wenn eine PV-Anlage am Standort vorhanden ist."
    },
    "ecDynamicWithPv": {
      "title": "In der Liegenschaft definiertes Stromprodukt (Netz+PV)",
      "body": "Auf Liegenschaftsebene kann im Bereich \"Stromkonfiguration\" festgelegt werden, welches Stromprodukt der Liegenschaft zugeordnet werden soll. Der Energieträger \"In der Liegenschaft definiertes Stromprodukt (Netz+PV)\" hat die Funktion eines Platzhalters für die vordefinierten und selbsterstellten Stromprodukte, welche in den Einstellungen/Energieträger & Emissionsfaktoren eingesehen werden können. Der Zusatz \"(Netz+PV)\" umschreibt den Umstand, dass sowohl der Netzbezug als auch die PV-Anlage am Standort, falls vorhanden, genutzt wird."
    }
  }
}
</i18n>

<template>
  <span>
    <InfoBox v-if="type === 'ELECTRICITY_DYNAMIC'" :title="$t('ecDynamic.title')">
      {{$t('ecDynamic.body')}}
    </InfoBox>
    <InfoBox v-if="type === 'ELECTRICITY_DYNAMIC_WITH_PV'" :title="$t('ecDynamicWithPv.title')">
      {{$t('ecDynamicWithPv.body')}}
    </InfoBox>
  </span>
</template>

<script>
import InfoBox from "@/components/shared/InfoBox.vue";

export default {
  components: {
    InfoBox,
  },

  props: {
    type: {
      type: String,
    },
  },
}
</script>
