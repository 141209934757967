<i18n>
{
  "de": {
    "editHeatingTitle": "Heizung bearbeiten",
    "createHeatingTitle": "Heizung erstellen",
    "editHeatpumpTitle": "Wärmepumpe bearbeiten",
    "createHeatpumpTitle": "Wärmepumpe erstellen"
  }
}
</i18n>

<template>
  <Modal class="c-edit-heating-type-modal" @close="$emit('close', $event)">
    <div v-if="error">
      <p>{{ error }}</p>
    </div>
    <template #header>
      <div v-if="type === 'BOILER'">
        <h2 v-if="!heatingType">{{ $t('createHeatingTitle') }}</h2>
        <h2 v-else>{{ $t('editHeatingTitle') }}</h2>
      </div>
      <div v-if="type === 'HEAT_PUMP'">
        <h2 v-if="!heatingType">{{ $t('createHeatpumpTitle') }}</h2>
        <h2 v-else>{{ $t('editHeatpumpTitle') }}</h2>
      </div>
    </template>
    <EditHeatingType
      :portfolio="portfolio"
      :heating-type="heatingType"
      :type="type"
      @save="onSave"
      @cancel="onCancel"
      @error="onError"
    />
  </Modal>
</template>

<script>
import EditHeatingType from '@/components/settings/heating_types/EditHeatingType.vue'
import Modal from '@/components/shared/Modal.vue'

export default {
  components: {
    Modal,
    EditHeatingType,
  },
  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    heatingType: {
      type: Object,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null,
    }
  },

  computed: {},

  methods: {
    onCancel() {
      if (this.heatingType === null) {
        this.$emit('close')
      }
    },

    onSave() {
      if (this.heatingType === null) {
        if (!this.error) {
          this.$emit('close')
        }
      }
    },

    onError(error) {
      this.error = error
    },
  },
}
</script>
